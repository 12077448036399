import React, { useState } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormButton } from '../components/form-elements';
import { useCartItemsContext } from "../components/cart-context";
import { useCheckoutContext } from "../components/checkout-context";
import { toCurrencyFormat } from "../helpers/currency-format-helper";
import { toDateFormat } from "../helpers/datetime-format-helper";
import { aggregateLineItems } from "../helpers/stripe-helper";
import { httpClient } from "../helpers/http-client-helper";
import usePickupAddress from "../hooks/use-pickup-address";
import { metadataFromCartItems } from "../helpers/metadata-helper";
import { FULFILLMENT } from "../constants";
import { navigate } from "gatsby";
import useCost from "../hooks/use-cost";
import CheckoutSteps from "../components/checkout-steps";
import CartOrderSummary from "../components/cart-order-summary";

export default function CheckoutReview() {
    return (
        <>
            <Seo title="Checkout Review" />
            <BrandHeading title="checkout" />
            <section>
                <CheckoutSteps step="review" />
                <div>
                    <h1 className="font-ovo text-center text-base tracking-wider text-gray-800 text-xl mb-2">REVIEW & PURCHASE</h1>
                    <p className="font-gotu italic text-center text-[.6rem] md:text-xs tracking-wider text-pas-gray">Please review your information and purchase when you're ready.</p>
                    <h1 className="font-ovo text-center text-sm md:text-base tracking-wide text-gray-800 mt-12 mb-4 md:mb-7">Order Summary</h1>
                    <CartOrderSummary />
                    <Fulfillment />
                    <Notes />
                    <SubTotal />
                    <Total />
                    <PurchaseForm />
                </div>
            </section>
        </>
    )
}


const PurchaseForm = () => {
    const cartItems = useCartItemsContext();
    const { customer, fulfillment, orderNotes } = useCheckoutContext();
    const { shippingRate, hasCakeTopperExtra, messageTopperProduct, hasDietaryOptionExtra, dietaryOptionProduct, hasCakeDecorationExtra, petiteDecorationProduct } = useCost();
    const [loading, setLoading] = useState(false);
    const lineItemCollection = cartItems.map(x => {
        return {
            price: x.selectedPriceId
            , quantity: x.quantity
            , hasCakeTopperExtra: hasCakeTopperExtra(x)
            , hasDietaryOptionExtra: hasDietaryOptionExtra(x)
            , hasCakeDecorationExtra: hasCakeDecorationExtra(x)
        }
    });
    const lineItemCakeTopperExtrasCollection = lineItemCollection
        .filter(x => x.hasCakeTopperExtra).map(x => { return { price: messageTopperProduct.id, quantity: x.quantity } });
    const lineItemDietaryOptionExtrasCollection = lineItemCollection
        .filter(x => x.hasDietaryOptionExtra).map(x => { return { price: dietaryOptionProduct.id, quantity: x.quantity } });
    const lineItemCakeDecorationExtrasCollection = lineItemCollection
        .filter(x => x.hasCakeDecorationExtra).map(x => { return { price: petiteDecorationProduct.id, quantity: x.quantity } });
    const lineItems = aggregateLineItems(lineItemCollection)
        .concat(aggregateLineItems(lineItemCakeTopperExtrasCollection))
        .concat(aggregateLineItems(lineItemDietaryOptionExtrasCollection))
        .concat(aggregateLineItems(lineItemCakeDecorationExtrasCollection));

    const params = {
        line_items: lineItems,
        shipping_rate: shippingRate.id,
        email: customer.email,
        metadata: { items: metadataFromCartItems(cartItems), customer, fulfillment, orderNotes }
    }

    const formSubmit = async (e, params) => {
        e.preventDefault();
        try {
            setLoading(true)
            const result = await httpClient().post("/create-checkout-session", params);
            window.location.replace(result.data);
        } catch (error) {
            console.error(error);
            setLoading(false)
        }
    };

    return (

        <div className="max-w-xs mx-auto my-12">
            <FormButton className="w-full mb-4" label={"PURCHASE"} onClick={(e) => formSubmit(e, params)} disabled={loading} isProcessing={loading} />
            <FormButton className="w-full mb-4" label={"BACK"} onClick={() => navigate(`/checkout-notes/`)} isSecondary={true} />
        </div>
    )
}

const Fulfillment = () => {
    const { fulfillment } = useCheckoutContext();
    return (
        <div>
            {(fulfillment.fulfillmentType === FULFILLMENT.DELIVERY) ? <DeliveryDetails /> : <PickupDetails />}
            <hr className="my-4" />
        </div>
    )
}


const Notes = () => {
    const { orderNotes } = useCheckoutContext();
    return (
        <div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    <span className="font-gotu text-[.65rem] md:text-xs tracking-wider text-pas-gray">
                        Notes
                    </span>
                </div>
                <div className="basis-2/3 font-ovo text-right text-xs md:text-[0.95rem] tracking-wider leading-[1.4rem] md:leading-6 text-gray-800 mt-1 pr-2">
                    <span>
                        {orderNotes && orderNotes.note?.trim() ? orderNotes.note : "None"}
                    </span>
                </div>
            </div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    <span className="font-gotu text-[.65rem] md:text-xs tracking-wider text-pas-gray">
                        Surprise gift</span>
                </div>
                <div className="basis-2/3 font-ovo text-right text-xs md:text-[0.95rem] tracking-wider leading-[1.4rem] md:leading-6 text-gray-800 mt-1 md:mt-0.5 pr-2">
                    <span>{orderNotes.isSurprise ? "Yes" : "No"}</span>
                </div>
            </div>


            <hr className="my-4" />
        </div>
    )
}

const DeliveryDetails = () => {
    const { customer, fulfillment } = useCheckoutContext();

    return (
        <div className="flex flew-row justify-between">
            <div className="basis-1/3">
                <span className="font-gotu text-[0.65rem] md:text-xs tracking-wider text-pas-gray">Delivery details</span>
            </div>
            <div className="basis-2/3 flex flex-col font-ovo text-right text-xs md:text-[0.95rem] tracking-wider leading-[1.4rem] md:leading-6 text-gray-800 mt-1 pr-2">
                <span className="text-sm md:text-lg tracking-wider">{customer.name}</span>
                <span>{customer.email}</span>
                <span>{customer.phone}</span>
                <span>{toDateFormat(fulfillment.collectionDate)}</span>
                <span><span className="font-gotu text-[0.65rem] md:text-xs text-pas-gray mr-1">deliver between </span> {fulfillment.collectionTime} </span>
                <span><span className="font-gotu text-[0.65rem] md:text-xs text-pas-gray mr-1">deliver to </span> {fulfillment.address}</span>
            </div>
        </div>
    )
}

const PickupDetails = () => {
    const { customer, fulfillment } = useCheckoutContext();

    return (
        <div className="flex flew-row justify-between">
            <div className="basis-1/3">
                <span className="font-gotu text-[0.65rem] md:text-xs tracking-wider text-pas-gray">Pick-up details</span>
            </div>
            <div className="basis-2/3 flex flex-col font-ovo text-right text-xs md:text-[0.95rem] tracking-wider leading-[1.4rem] md:leading-6 text-gray-800 mt-1 pr-2">
                <span className="text-sm md:text-lg tracking-wider">{customer.name}</span>
                <span>{customer.email}</span>
                <span>{customer.phone}</span>
                <span>{toDateFormat(fulfillment.collectionDate)}</span>
                <span><span className="font-gotu text-[0.65rem] md:text-xs text-pas-gray mr-1">pick up between </span> {fulfillment.collectionTime} </span>
                <span><span className="font-gotu text-[0.65rem] md:text-xs text-pas-gray mr-1">pick up at </span> {usePickupAddress()}</span>
            </div>
        </div>
    )
}


const SubTotal = () => {
    const { shippingRate, subTotal } = useCost()

    const { fulfillment } = useCheckoutContext();
    const isDelivery = (fulfillment.fulfillmentType === FULFILLMENT.DELIVERY);

    return (
        <div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    <span className="font-gotu text-xs tracking-wider text-pas-gray">Subtotal</span>
                </div>
                <div className="basis-2/3 font-ovo text-right text-base md:text-lg tracking-wider leading-6 text-gray-800 mt-0.5 pr-2">
                    {toCurrencyFormat(subTotal)}
                </div>
            </div>
            <div className="flex flew-row justify-between">
                <div className="basis-1/3">
                    <span className="font-gotu text-[.65rem] md:text-xs tracking-wider text-pas-gray">{isDelivery ? "Delivery" : "Pick-up"}</span>
                </div>
                <div className="basis-2/3 font-ovo text-right text-base md:text-lg tracking-wider leading-6 text-gray-800 mt-0.5 pr-2">
                    {isDelivery ? toCurrencyFormat(shippingRate.amount) : "$0.00"}
                </div>
            </div>
            <hr className="my-4" />
        </div>
    )
}

const Total = () => {
    const { checkOutTotal, gstCost } = useCost();

    return (
        <div>
            <div className="flex flew-row justify-between items-center">
                <div className="flex flex-col">
                    <span className="font-ovo text-lg tracking-wide text-gray-800 mb-.5">TOTAL</span>
                    <span className="text-[.6rem] md:text-xs tracking-wider text-pas-gray">
                        Includes {toCurrencyFormat(gstCost)} gst</span>
                </div>
                <div className="font-ovo text-right text-2xl md:text-3xl tracking-wider leading-6 text-gray-800">
                    <div className="inline-flex items-center">
                        <span className="text-sm md:text-base tracking-wide mr-1.5">
                            NZ </span>
                        <span>
                            {toCurrencyFormat(checkOutTotal)}
                        </span>
                    </div>
                </div>
            </div>
            <hr className="my-4" />
        </div>
    )
}


